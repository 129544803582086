<template>
    <modal ref="modalPazSalvo" titulo="Paz y salvo" class="modal-paz-salvo" :cargando="cargando" :adicional="`${meses.length ? 'Guardar' : ''}`" tamano="modal-md" @adicional="registrarPazSalvo">
        <div v-if="statusCheck">
            <div v-if="meses.length" class="col-12 text-center mb-3">
                <p>Selecciona el mes hasta el cual va a quedar en paz y salvo.</p>
            </div>
            <div v-if="meses.length" class="container overflow-auto custom-scroll" style="height:calc(60vh - 200px)">
                <div v-for="(mes,index) in meses" :key="index" class="row mx-2 my-3">
                    <div class="col-auto">
                        <el-checkbox v-model="mes.checked" class="el-checkbox-negro" :true-label="1" :false-label="0" @change="seleccionarFecha(index,mes.fecha_pago_mes)">
                            <span class="f-18">{{ _.startCase(formatearFecha(mes.fecha_pago_mes,'MMMM YYYY')) }}</span>
                        </el-checkbox>
                    </div>
                    <div class="col d-middle justify-content-end">
                        <span :class="`f-18 ${mes.vencido ? 'text-orange f-500 mr-2' : 'f-500'} `">{{ convertMoney(mes.valor) }}</span>
                        <i v-show="mes.vencido" class="text-orange icon-alerta_2 f-18" />
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <i class="col">
                    No hay meses disponibles para pagar.
                </i>
            </div>
        </div>
        <div v-else>
            <p class="text-center">{{ mensaje }}</p>
        </div>
    </modal>
</template>
<script>
import Viviendas from '~/services/viviendas'
export default {
    data(){
        return{
            cargando: false,
            meses: [],
            mensaje: null,
            statusCheck: false,
            fecha: ''
        }
    },
    methods:{
        toggle(){
            this.getListadoMesesAPagar()
            this.$refs.modalPazSalvo.toggle()
        },
        async getListadoMesesAPagar(){
            try {
                const { data } = await Viviendas.listarMesesDisponiblesAPagar(this.$route.params.id_vivienda)
                this.meses = data.data.map(el=>{el.checked = 0;return el})

                this.statusCheck = data.success
                this.mensaje = data.mensaje
            } catch (error){
                this.errorCatch(error)
            }
        },
        seleccionarFecha(index,fecha){
            this.fecha = fecha
            this.meses.forEach((el,indexEach)=>{
                if(index >= indexEach){
                    if (index == indexEach){
                        if (this.meses[indexEach].checked != 1){
                            this.meses[indexEach].checked = 0
                        }
                    }else{
                        this.meses[indexEach].checked = 1
                    }
                } else{
                    this.meses[indexEach].checked = 0
                }
            })
        },
        async registrarPazSalvo(){
            try {
                let mesesSomeChecked = this.meses.some(el=>el.checked == 1)

                if(!mesesSomeChecked) return this.notificacion('','Por favor selecciona al menos un mes a pagar')

                this.cargando = true 
                
                let params = {
                    id_vivienda: this.$route.params.id_vivienda,
                    fecha: this.fecha
                }

                const { data } = await Viviendas.ponerPazYSalvo(params)

                this.$emit('actualizar')

                this.notificacion('','Vivienda puesta a paz y salvo correctamente','success')
                this.$refs.modalPazSalvo.toggle()

            } catch (error){
                this.errorCatch(e)
            } finally{
                this.cargando = false
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.modal-paz-salvo{
    .modal-sm{
        min-width: 390px !important;
    }
}
</style>